import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { select, Store } from '@ngxs/store';
import { NzCardModule } from 'ng-zorro-antd/card';
import { ConfigurationSelectors } from '../../store/configuration/configuration.selectors';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [CommonModule, NzCardModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent {
  store = inject(Store);
  user = select(ConfigurationSelectors.user);
}
